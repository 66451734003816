<!--
 * @Author: dingguowei
 * @Date: 2023-06-08 17:00:23
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-18 14:52:24
-->
<!-- 编辑任务 -->
<template>
  <div class="publishTask">
    <div class="container">
      <div class="left">
        <!-- 基本任务信息 -->
        <template v-if="process === 1">
          <el-card>
            <div class="title">
              <div class="deco"></div>
              <span>发布文章信息</span>
            </div>
            <div class="body">
              <div class="item">
                <p>文章标题</p>
                <el-input v-model="taskInfo.title" size="mini"></el-input>
              </div>
              <div class="item">
                <p>任务标签</p>
                <el-select
                  v-model="taskInfo.typeIdList"
                  placeholder="请选择项目类型"
                  size="mini"
                  style="width: 14rem"
                  multiple
                >
                  <el-option
                    v-for="item in taskTypes"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="item">
                <p>文章内容</p>
                <mavon-editor
                  id="content"
                  ref="content"
                  v-model="content"
                  @change="changecontent"
                  @imgAdd="contentimgAdd"
                  style="height: 500px; overflow: auto"
                ></mavon-editor>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 10px">
            <div class="title">
              <div class="deco"></div>
              <span>任务类型</span>
            </div>
            <div class="body">
              <div class="item">
                <p>任务类型</p>
                <el-select
                  v-model="taskInfo.category"
                  placeholder="请选择任务类型"
                  size="mini"
                  style="width: 14rem; margin-top: 10px"
                >
                  <el-option label="互动任务" :value="0"> </el-option>
                  <el-option label="分发任务" :value="1"> </el-option>
                  <el-option label="佣金任务" :value="2"> </el-option>
                </el-select>
              </div>
            </div>
          </el-card>
          <el-card style="margin-top: 10px" v-if="taskInfo.category === 0">
            <div class="title">
              <div class="deco"></div>
              <span>任务指标</span>
            </div>
            <div class="body">
              <el-row class="item">
                <el-col :span="8">
                  <p>总预算</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.budgetTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>总投流需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.flowTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>gmv需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.gmvTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row class="item">
                <el-col :span="8">
                  <p>爆文标准</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.hotSet"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>爆文需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.hotTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>互动达标标准</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.interactSet"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row class="item">
                <el-col :span="8">
                  <p>互动达标需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.interactTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>koc水下需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.kocDownTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>koc水上需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.kocUpTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row class="item">
                <el-col :span="8">
                  <p>kol水下需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.kolDownTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>kol水上需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.kolUpTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <p>pv需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.pvTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row class="item">
                <el-col :span="8">
                  <p>素人需求数</p>
                  <el-input
                    v-positive-integer
                    v-model="taskInfo.standardSaveQo.simpleTotal"
                    size="mini"
                    style="width: 70%; margin-top: 6px"
                  ></el-input>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <!-- 完善任务信息 -->
          <el-card style="margin: 10px 0">
            <div class="title">
              <div class="deco"></div>
              <span>完善任务信息</span>
            </div>
            <div class="body">
              <!-- 各种奖励 -->
              <!-- 任务周期 -->
              <div class="item">
                <p>任务周期</p>
                <el-row>
                  <el-col :span="12">
                    <span>开始时间</span>
                    <el-date-picker
                      v-model="taskInfo.startAt"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      size="mini"
                      style="width: 180px; margin-left: 10px"
                    >
                    </el-date-picker>
                  </el-col>
                  <el-col :span="12">
                    <span>结束时间</span>
                    <el-date-picker
                      v-model="taskInfo.endAt"
                      type="datetime"
                      placeholder="选择日期时间"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      size="mini"
                      style="width: 180px; margin-left: 10px"
                    >
                    </el-date-picker>
                  </el-col>
                </el-row>
              </div>
              <!-- 需求人数 -->
              <div class="item">
                <p>需求人数</p>
                <el-input
                  size="mini"
                  style="width: 160px; margin: 0 10px; margin-top: 10px"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  v-model="taskInfo.requiredNum"
                ></el-input
                >人
              </div>

              <div class="item">
                <p
                  style="font-size: 0.8rem; line-height: 200%; font-weight: 600"
                >
                  收益发放规则
                </p>
                <el-row style="margin-top: 10px">
                  首款： 提交
                  <el-input
                    v-positive-integer
                    size="mini"
                    v-model="taskInfo.downDays"
                    style="width: 80px; margin: 0 10px"
                  ></el-input>
                  天后发放
                  <el-input
                    v-positive-decimal
                    size="mini"
                    v-model="taskInfo.downPayment"
                    style="width: 80px; margin: 0 10px"
                  ></el-input>
                  元
                </el-row>
                <el-row style="margin-top: 10px">
                  尾款： 提交<el-input
                    v-positive-integer
                    size="mini"
                    style="width: 80px; margin: 0 12px"
                    v-model="taskInfo.finalDays"
                  ></el-input>
                  天后发放
                  <el-input
                    size="mini"
                    v-positive-decimal
                    style="width: 80px; margin: 0 10px"
                    v-model="taskInfo.finalPayment"
                  ></el-input>
                  元
                </el-row>
                <el-row
                  style="margin-top: 10px"
                  v-for="(salary, index) in taskInfo.saveQoList"
                  :key="index"
                >
                  <span
                    v-for="(item, index) in conditions"
                    :key="index"
                    style="margin-top: 10px; font-size: 0.7rem"
                  >
                    <span
                      >{{ item.name
                      }}{{ item.name === "奖励" ? "" : "满" }}</span
                    >
                    <el-input
                      size="mini"
                      style="width: 80px; margin: 0 10px"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      v-model="salary[item.fieId]"
                    ></el-input
                    >{{ item.name === "奖励" ? "元" : "" }}
                  </span>
                  <i
                    v-if="salary.type === 0 && index == 0"
                    class="iconfont icon-tianjia"
                    style="margin-left: 20px"
                    @click="add"
                  ></i>
                  <i
                    v-else-if="salary.type === 0 && index !== 0"
                    class="iconfont icon-jianshao"
                    style="margin-left: 20px"
                    @click="remove(salary)"
                  ></i>
                </el-row>
              </div>
              <!-- 任务示例 -->
              <div class="item">
                <p>任务示例</p>
                <mavon-editor
                  ref="example"
                  v-model="example"
                  @change="changeexample"
                  @imgAdd="exampleimgAdd"
                  style="height: 500px; overflow: auto"
                ></mavon-editor>
              </div>
            </div>
          </el-card>
        </template>
        <template v-else>
          <config-task :task-info="taskInfo"></config-task>
        </template>
      </div>
      <div class="right">
        <el-card>
          <el-button v-if="process == 1" size="mini" @click="process = 2">
            下一步</el-button
          >
          <template v-else>
            <el-button size="mini" @click="process = 1">
              继续编辑任务</el-button
            >
            <el-button
              size="mini"
              @click="editTask(0)"
              style="margin: 10px 0 0 0"
            >
              保存任务</el-button
            >
            <el-button
              size="mini"
              @click="editTask(1)"
              style="margin: 10px 0 0 0"
            >
              保存并发布任务</el-button
            >
          </template>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import router from "@/router";
import "mavon-editor/dist/css/index.css";
import { uploadimg, getTaskList, editTask } from "@/api/home";
import configTask from "@/components/task/config_task.vue";
import { positiveInteger, positiveDecimal } from "@/config/directives";
export default {
  directives: {
    positiveInteger,
    positiveDecimal,
  },
  components: {
    mavonEditor,
    configTask,
  },
  data() {
    return {
      process: 1,
      // 发布任务填写的信息
      taskInfo: {
        category: 0, //任务类型
        description: "",
        advancePercent: 1,
        downPayment: "",
        downDays: "",
        finalPayment: "",
        finalDays: "",
        example: "",
        startAt: null,
        endAt: null,
        requiredNum: 0,
        saveQoList: [
          {
            totalNum: 0,
            salary: 0,
            likeNum: 0,
            shareNum: 0,
            collectNum: 0,
            commentNum: 0,
            requiredNum: 0,
            type: 0,
          },
        ],
        standardSaveQo: {
          budgetTotal: 0,
          flowTotal: 0,
          gmvTotal: 0,
          hotSet: 0,
          hotTotal: 0,
          interactSet: 0,
          interactTotal: 0,
          kocDownTotal: 0,
          kocUpTotal: 0,
          kolDownTotal: 0,
          kolUpTotal: 0,
          pvTotal: 0,
          simpleTotal: 0,
        },
        status: 0,
        title: "",
        typeIdList: [],
      },
      taskTypes: [],
      // 完成条件类目
      conditions: [
        {
          name: "互动数",
          fieId: "totalNum",
        },
        {
          name: "奖励",
          fieId: "salary",
        },
        // {
        //   name: "分享数",
        //   fieId: "shareNum",
        // },
        // {
        //   name: "评论数",
        //   fieId: "commentNum",
        // },
        // {
        //   name: "收藏数",
        //   fieId: "collectNum",
        // },
      ],
      content: "",
      example: "",
    };
  },
  created() {},
  async mounted() {
    if (!this.$route.params.value) {
      router.go(-1);
    }
    await this.restoreData(this.$route.params.value);
    await this.getTaskList();
  },
  watch: {
    process: {
      handler(newvalue) {
        this.scrolltoTop();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  methods: {
    // 恢复数据
    async restoreData(val) {
      this.taskInfo.category = val.category;
      this.taskInfo.title = val.title;
      this.taskInfo.description = val.description;
      this.content = val.description;
      this.taskInfo.example = val.example;
      this.example = val.example;
      this.taskInfo.cashDays = val.cashDays;
      this.taskInfo.endAt = val.endAt;
      this.taskInfo.startAt = val.startAt;
      this.taskInfo.saveQoList = val.salaryListVos;
      this.taskInfo.id = val.id;
      this.taskInfo.advancePercent = val.advancePercent;
      this.taskInfo.finalDays = val.finalDays;
      this.taskInfo.finalPayment = val.finalPayment;
      this.taskInfo.downDays = val.downDays;
      this.taskInfo.downPayment = val.downPayment;
      this.taskInfo.budget = val.budget;
      this.taskInfo.requiredNum = val.requiredNum;
      if (val.standardVo) {
        this.taskInfo.standardSaveQo = val.standardVo;
      }
    },
    // 获取项目类别
    async getTaskList() {
      await getTaskList().then((res) => {
        this.taskTypes = res.data;
      });
    },
    changecontent(value, html) {
      this.taskInfo.description = html;
    },
    changeexample(value, html) {
      this.taskInfo.example = html;
    },
    async contentimgAdd(pos, $file) {
      let str = this.base64toFile($file.miniurl, "file"); //base64图片格式转文件流
      let formData = new FormData();
      formData.append("folder", "mission");
      formData.append("file", str);
      await uploadimg(formData).then((res) => {
        this.$refs.content.$img2Url(pos, res.data);
      });
    },
    async exampleimgAdd(pos, $file) {
      let str = this.base64toFile($file.miniurl, "file"); //base64图片格式转文件流
      let formData = new FormData();
      formData.append("folder", "mission");
      formData.append("file", str);
      await uploadimg(formData).then((res) => {
        this.$refs.example.$img2Url(pos, res.data);
      });
    },

    base64toFile(data, fileName) {
      const dataArr = data.split(",");
      const byteString = atob(dataArr[1]);
      const options = {
        type: "image/jpeg",
        endings: "native",
      };
      const u8Arr = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        u8Arr[i] = byteString.charCodeAt(i);
      }
      return new File([u8Arr], fileName + ".jpg", options); //返回文件流
    },
    async scrolltoTop() {
      this.$nextTick(() => {
        var container = this.$el.querySelector(".left");
        container.scrollTop = 0;
      });
    },
    async editTask(val) {
      this.taskInfo.status = val;
      let params = this.taskInfo;
      if (this.taskInfo.category !== 0) {
        params.standardSaveQo = null;
      }
      await editTask(params).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          router.push({ name: "发布任务管理" });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 校验百分比
    paycheck(val) {
      let value = parseInt(val.replace(/[^\d]/g, ""));

      if (isNaN(value)) {
        this.taskInfo.advancePercent = null;
      } else if (value < 1) {
        this.taskInfo.advancePercent = 1;
      } else if (value > 99) {
        this.taskInfo.advancePercent = 99;
      } else {
        this.taskInfo.advancePercent = value;
      }
    },
    // 增加删除奖励操作
    add() {
      // this.taskInfo.saveQoList.splice(this.taskInfo.saveQoList.length - 1, 0, {
      //   totalNum: 0,
      //   salary: 0,
      //   type: 0,
      // });
      this.taskInfo.saveQoList.push({
        totalNum: 0,
        salary: 0,
        type: 0,
      });
    },
    remove(val) {
      this.taskInfo.saveQoList.map((res) => {
        if (res === val) {
          this.taskInfo.saveQoList.splice(
            this.taskInfo.saveQoList.indexOf(val),
            1
          );
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border: 5px solid transparent;
}

::-webkit-scrollbar-track {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
}

::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-clip: content-box;
  box-shadow: 0 0 0 5px rgba(23, 58, 230, 0.653) inset;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.publishTask {
  color: black;
  box-sizing: border-box;
  padding: 1rem 2rem;

  .container {
    // overflow: auto;
    display: flex;
    height: 100%;

    .left {
      height: 100%;
      flex: 1;
      overflow: auto;

      .title {
        display: flex;
        align-items: center;

        .deco {
          width: 4px;
          height: 16px;
          background-color: #6080ef;
        }

        & span {
          margin-left: 15px;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }

      .body {
        padding: 10px 20px;
        // height: 50rem;
        overflow: auto;

        .item {
          margin-bottom: 10px;
          font-size: 0.7rem;

          & p {
            font-size: 0.8rem;
            line-height: 200%;
            font-weight: 600;
          }
        }
      }

      ::v-deep .el-card__body {
        padding-left: 0;
      }
    }

    .right {
      box-sizing: border-box;
      margin-left: 16px;
      width: 16rem;
      height: auto;

      ::v-deep .el-card__body {
        padding: 20px 4rem;
      }

      ::v-deep .el-button {
        width: 100%;
        color: #5874ef;
        border-color: #5874ef;
      }

      ::v-deep .el-button:hover {
        color: #fff;
        background-color: #5874ef;
      }
    }
  }
}
</style>