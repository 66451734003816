<!--
 * @Author: dingguowei
 * @Date: 2023-06-07 11:31:59
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 18:07:48
-->
<!-- 参与用户页面-->
<template>
  <div class="tabs_user">
    <div>
      <span style="font-size: 0.8rem">参与用户</span>
    </div>
    <div class="body" v-if="tableData.length > 0">
      <div class="item" v-for="(item, index) in tableData" :key="index">
        <div class="left">
          <p style="font-size: 1rem">{{ item.receiverAccount }}</p>
          <p style="font-size: 0.7rem; color: #999999">
            {{
              item.updatedOn
                ? "提交时间 ：" + item.updatedOn
                : "创建时间 ：" + item.createdOn
            }}
          </p>
        </div>
        <div class="right">
          <p style="font-size: 0.9rem; color: #fe855e">
            {{ statusText(item.status) }}
          </p>
          <el-button size="mini" @click="goauditDetail(item)"
            >查看详情</el-button
          >
          <el-button
            v-if="
              item.status !== 'FINISHED' &&
              item.status !== 'CLOSED' &&
              item.status !== 'FORBID'
            "
            style="border-color: #fe855e; color: #fe855e; margin-top: 4px"
            size="mini"
            @click="closeTask(item.id)"
            >关闭任务</el-button
          >
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无数据" class="body"></el-empty>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="total"
      style="margin-left: AUTO; margin-top: 10px; display: block"
      :pager-count="5"
      small
      @current-change="getJoinuser"
      :current-page.sync="pageNo"
      :page-size="pageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
import store from "@/store";

import router from "@/router";
import { gettaskInfo, getjoinuser, closejoinuser } from "@/api/home";
import { mapState } from "vuex";
export default {
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      tableData: [], //参与用户数据
      total: 0,
      pageSize: 10,
      pageNo: 1,
      status: null,
    };
  },
  created() {},
  mounted() {
    this.getJoinuser();
  },
  watch: {},
  computed: {
    ...mapState("task", ["taskInfo"]),
    statusText() {
      return function (val) {
        if (val === "TOJUDGE") {
          return "待审核";
        } else if (val === "CLOSED") {
          return "已关闭";
        } else if (val === "FINISHED") {
          return "通过";
        } else if (val === "REFUSED") {
          return "拒绝";
        } else if (val === "FORBID") {
          return "拉黑";
        } else if (val === "TOFINISH") {
          return "进行中";
        }
      };
    },
  },
  methods: {
    // 获取参与用户
    getJoinuser(val) {
      getjoinuser({
        missionId: this.taskInfo.missionId,
        status: this.status,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then((res) => {
        if (val) {
          this.$message({
            message: "查询成功",
            type: "success",
          });
        }
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 查看详情
    goauditDetail(val) {
      store.commit("task/CHANGE_MISSIONID", val.missionId);
      store.commit("task/CHANGE_RECEIVERID", val.receiverId);
      store.commit("task/CHANGE_missionReceiveId", val.id);

      router.push({
        name: "审核任务详情",
        params: {
          status: val.status,
        },
      });
    },
    changejoinuser(val) {
      this.status = val;
      this.pageNo = 1;
      this.getJoinuser(val);
    },
    closeTask(val) {
      closejoinuser({
        missionReceiveId: val,
      }).then((res) => {
        if (res.code === 1000) {
          this.getJoinuser();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs_user {
  height: 100%;
  display: flex;
  flex-direction: column;

  .handel:hover {
    color: #5874ef;
  }

  .body {
    flex: 1;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 14px;
      height: 14px;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
      border-radius: 999px;
      border: 5px solid transparent;
    }

    ::-webkit-scrollbar-track {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
    }

    ::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 5px rgba(23, 58, 230, 0.653) inset;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    .item {
      padding: 5px 6px;
      display: flex;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      border-bottom: 2px solid #e6e6e6;
      line-height: 180%;

      ::v-deep .el-button--mini {
        padding: 4px 6px;
        color: #5874ef;
        border-color: #5874ef;
      }

      & p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .left {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .right {
        padding: 0 4px 0 10px;
        text-align: right;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>