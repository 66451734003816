var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"userNum"},[_c('div',{staticClass:"item start"},[_c('p',{staticStyle:{"font-size":"1rem"}},[_vm._v(_vm._s(_vm.statusText))]),_c('p',{staticStyle:{"font-size":"0.7rem"}},[_vm._v("任务状态")])]),_vm._l((_vm.number),function(item,index){return _c('div',{key:index,staticClass:"item",style:([
    {
      backgroundImage:
        _vm.status !== item.status
          ? `url(${item.bgcUrl})`
          : `url(${item.activebgcUrl})`,
    },
  ]),on:{"click":function($event){return _vm.changejoinuser(item.status)}}},[_c('el-progress',{attrs:{"type":"circle","percentage":_vm.percentage(_vm.taskInfo.totalNum, _vm.taskInfo[item.fieId]),"width":70,"format":_vm.format(_vm.taskInfo[item.fieId]),"color":_vm.status !== item.status ? '#8FABFF' : '#4DFFFC',"text-color":_vm.status === item.status ? '#4DFFFC' : '#6080EF',"define-back-color":"#d1d1d170"}}),_c('p',{staticClass:"des",style:([{ color: _vm.status === item.status ? '#fff' : '#000' }])},[_vm._v(" "+_vm._s(item.name)+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }