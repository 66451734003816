<!--
 * @Author: dingguowei
 * @Date: 2023-06-06 15:16:33
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-04 18:48:41
-->
<!-- 首页任务详情页面 -->
<template>
  <div class="taskdetails">
    <div class="left">
      <taskheadInfo :task-info="taskData"></taskheadInfo>

      <task_user_number :task-info="taskData.statVo" :status-text="statusText(taskData.status)"
        @changejoinuser="changejoinuser"></task_user_number>
      <el-card class="tabs">
        <el-tabs v-model="activeName">
          <el-tab-pane label="文章详情" name="文章详情">
            <div class="body" style="font-size: 0.8rem" v-html="taskData.description"></div>
          </el-tab-pane>
          <el-tab-pane label="任务详情" name="任务详情">
            <task_tabs_details :task-info="taskData.salaryListVos" :money-data="taskData"></task_tabs_details>
          </el-tab-pane>
          <!-- <el-tab-pane label="内容管理" name="内容管理" style="height: 100%">
            <content_mgt />
          </el-tab-pane> -->
        </el-tabs>
      </el-card>
    </div>
    <div class="right">
      <el-card>
        <!-- <div slot="header" class="clearfix" :style="{ color: statusColor(taskData.status) }">
                                                          <span>{{ statusText(taskData.status) }}</span>
                                                        </div> -->
        <div style="text-align: center">
          <p style="font-size: 0.8rem; font-weight: 600">任务操作</p>
          <el-button plain v-if="taskData.status !== 1" size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(1), borderColor: statusColor(1) }" @click="upTask">任务上架</el-button>
          <el-button plain v-if="taskData.status !== 0" size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(0), borderColor: statusColor(0) }" @click="downTask">任务下架</el-button>
          <el-button plain v-if="taskData.status !== 2" size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(2), borderColor: statusColor(2) }" @click="closeTask">任务关闭</el-button>
          <el-button plain size="mini" style="width: 60%; margin-top: 10px"
            :style="{ color: statusColor(), borderColor: statusColor() }" @click="editTask">任务编辑</el-button>
        </div>
      </el-card>
      <el-card style="flex: 1; margin-top: 10px">
        <task_tabs_user ref="tabsuser"></task_tabs_user>
      </el-card>
    </div>
  </div>
</template>

<script>
import { gettaskInfo, upTask, downTask, closeTask } from "@/api/home";
import { mapState } from "vuex";
// 组件
import taskheadInfo from "@/components/task/taskheadInfo.vue";
import task_tabs_details from "@/components/task/task_tabs_details.vue";
import task_tabs_user from "@/components/task/task_tabs_user.vue";
import task_user_number from "@/components/task/task_user_num.vue";
// import content_mgt from "@/components/task/content_mgt.vue";
import editTaskVue from "./editTask.vue";
import router from "@/router";
export default {
  components: {
    taskheadInfo,
    task_tabs_details,
    task_tabs_user,
    task_user_number,
    // content_mgt,
  },
  data() {
    return {
      taskData: {},
      activeName: "文章详情",
    };
  },
  created() { },
  mounted() {
    this.gettaskInfo();
  },
  watch: {},
  computed: {
    ...mapState("task", ["taskInfo"]),
    statusText() {
      return function (val) {
        if (val === 0) {
          return "任务已下架";
        } else if (val === 1) {
          return "任务已上架";
        } else if (val === 2) {
          return "任务已关闭";
        }
      };
    },
    statusColor() {
      return function (val) {
        if (val === 1) {
          return "#67C23A";
        } else if (val === 0) {
          return "#E6A23C";
        } else if (val === 2) {
          return "#F56C6C";
        } else {
          return "#909399";
        }
      };
    },
  },
  methods: {
    // 获取点进来的任务详情
    gettaskInfo() {
      gettaskInfo({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        this.taskData = res.data;
      });
    },
    async upTask() {
      await upTask({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功上架",
            type: "success",
          });
          this.gettaskInfo();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    async downTask() {
      await downTask({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功下架",
            type: "success",
          });
          this.gettaskInfo();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    async closeTask() {
      await closeTask({
        missionId: this.taskInfo.missionId,
      }).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: "成功关闭",
            type: "success",
          });
          this.gettaskInfo();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    editTask() {
      router.push({ name: "编辑任务", params: { value: this.taskData } });
    },
    changejoinuser(val) {
      this.$refs.tabsuser.changejoinuser(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.taskdetails {
  box-sizing: border-box;
  padding: 1rem 2rem 1rem 2rem;
  display: flex;

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
  }

  ::-webkit-scrollbar-thumb {
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(23, 58, 230, 0.653) inset;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .tabs {
      flex: 1;
      overflow: auto;

      ::v-deep .el-tabs {
        height: 100% !important;
        display: flex;
        flex-direction: column;
        // overflow: auto;
      }

      ::v-deep .el-tabs__content {
        flex: 1;
        width: 100%;
        overflow: overlay;
      }

      ::v-deep .el-tabs__item {
        font-size: 0.8rem;
      }

      ::v-deep .el-card__body {
        box-sizing: border-box;
        height: 100%;
      }

      .body {
        // height: 200vh;
        // background-color: #7f1919;
      }
    }
  }

  .right {
    width: 20rem;
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    ::v-deep .el-button {
      margin-left: 0;
    }

    ::v-deep .el-card__body {
      height: 100%;
      box-sizing: border-box;
    }

    .clearfix {
      font-weight: 600;
      font-size: 0.9rem;
      text-align: center;
    }
  }
}
</style>