import { render, staticRenderFns } from "./task_user_num.vue?vue&type=template&id=3844ecd1&scoped=true&"
import script from "./task_user_num.vue?vue&type=script&lang=js&"
export * from "./task_user_num.vue?vue&type=script&lang=js&"
import style0 from "./task_user_num.vue?vue&type=style&index=0&id=3844ecd1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3844ecd1",
  null
  
)

export default component.exports