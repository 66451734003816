<!--
 * @Author: dingguowei
 * @Date: 2023-06-12 09:24:51
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-07-07 17:46:00
-->
<!-- 发布任务各用户数量详情 -->
<template>
  <div class="userNum">
    <div class="item start">
      <p style="font-size: 1rem">{{ statusText }}</p>
      <p style="font-size: 0.7rem">任务状态</p>
    </div>
    <div class="item" :style="[
      {
        backgroundImage:
          status !== item.status
            ? `url(${item.bgcUrl})`
            : `url(${item.activebgcUrl})`,
      },
    ]" v-for="(item, index) in number" :key="index" @click="changejoinuser(item.status)">
      <el-progress type="circle" :percentage="percentage(taskInfo.totalNum, taskInfo[item.fieId])" :width="70"
        :format="format(taskInfo[item.fieId])" :color="status !== item.status ? '#8FABFF' : '#4DFFFC'"
        :text-color="status === item.status ? '#4DFFFC' : '#6080EF'" define-back-color="#d1d1d170">
      </el-progress>
      <p class="des" :style="[{ color: status === item.status ? '#fff' : '#000' }]">
        {{ item.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusText: {
      type: String,
      default: "",
    },
    taskInfo: {
      type: Object,
      default: () => {
        return {
          totalNum: 0,
          submittedNum: 0,
          finishedNum: 0,
          refusedNum: 0,
          forbidNum: 0,
        };
      },
    },
  },
  components: {},
  data() {
    return {
      number: [
        {
          name: "总参与用户",
          num: "14/17",
          percentage: 100,
          fieId: "totalNum",
          bgcUrl: require("@/assets/audit/aaa.png"),
          activebgcUrl: require("@/assets/audit/aa.png"),
          status: null,
        },
        {
          name: "待审核用户",
          num: "",
          percentage: 100,
          fieId: "submittedNum",
          status: "TOJUDGE",
          bgcUrl: require("@/assets/audit/aaa.png"),
          activebgcUrl: require("@/assets/audit/aa.png"),
        },
        {
          name: "通过用户",
          num: "",
          percentage: 100,
          fieId: "finishedNum",
          status: 'FINISHED',
          bgcUrl: require("@/assets/audit/aaa.png"),
          activebgcUrl: require("@/assets/audit/aa.png"),
        },
        {
          name: "被拒绝用户",
          num: "14/17",
          percentage: 100,
          fieId: "refusedNum",
          status: 'REFUSED',
          bgcUrl: require("@/assets/audit/aaa.png"),
          activebgcUrl: require("@/assets/audit/aa.png"),
        },
        {
          name: "被拉黑用户",
          num: "14/17",
          percentage: 100,
          fieId: "forbidNum",
          status: 'FORBID',
          bgcUrl: require("@/assets/audit/aaa.png"),
          activebgcUrl: require("@/assets/audit/aa.png"),
        },
      ],
      status: null,
    };
  },
  created() { },
  mounted() { },
  watch: {},
  computed: {},
  methods: {
    // 自定义内容
    format(e) {
      return () => {
        return e + "";
      };
    },
    percentage(total, per) {
      if (total == 0) {
        return 0;
      } else {
        return (parseInt(per) / parseInt(total)) * 100;
      }
    },
    changejoinuser(val) {
      this.status = val;
      this.$emit("changejoinuser", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.userNum {
  // margin-bottom: 10px;
  color: #000000;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0 0 1rem 0;

  .item {
    border-radius: 6px;
    box-sizing: border-box;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex: 1;
    background: #ffffff;
    margin-right: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-right: 0;
    }
  }

  .start {
    background: linear-gradient(180deg, #91acf8 0%, #5874ef 100%);
    font-weight: bold;
    color: #ffffff;
    line-height: 200%;
  }

  // ::v-deep.el-progress-bar__inner {
  //     background-color: unset;
  //     background-image: linear-gradient(to right, #80A4CB, #CCE0F3);
  // }

  .des {
    text-align: center;
    color: #393939;
    font-size: 0.8rem;
    line-height: 180%;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
  }
}
</style>
<style lang="scss"></style>
