<!--
 * @Author: dingguowei
 * @Date: 2023-06-06 16:26:34
 * @LastEditors: dingguowei
 * @LastEditTime: 2023-06-15 10:48:16
-->
<!-- 头部具体任务信息组件 -->
<template>
  <el-card class="taskheadInfo">
    <span>{{ taskInfo.title }}</span>
    <div
      style="
        float: right;
        display: flex;
        align-items: center;
        justify-content: center;
      "
    >
      <div
        class="circle"
        :style="{ background: statusColor(taskInfo.status) }"
      ></div>
      <span
        :style="{ color: statusColor(taskInfo.status) }"
        style="font-weight: 500"
        >{{ statusText(taskInfo.status) }}</span
      >
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    taskInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    statusColor() {
      return function (val) {
        if (val === 1) {
          return "#67C23A";
        } else if (val === 0) {
          return "#E6A23C";
        } else {
          return "#F56C6C";
        }
      };
    },
    statusText() {
      return function (val) {
        if (val === 1) {
          return "已上架";
        } else if (val === 0) {
          return "已下架";
        } else if (val === 2) {
          return "关闭";
        }
      };
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.taskheadInfo {
  // height: 8rem;
  color: black;
  box-sizing: border-box;
  // padding: 1rem 2rem;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 0.9rem;
  font-weight: 600;

  .circle {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 4px;
  }
}
</style>